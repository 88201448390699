import { useSearchParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import axios from 'axios'

const {
  Server,
  Asset,
  Transaction,
  Networks,
} = require("stellar-sdk")


const server = new Server("https://horizon.stellar.org")
const txHash = "f651431e9d8b8a1f8aa5eee6250502905a387edbf95bee09db3a0ad427ad6605"


const getAuthToken = async () => {
  const response  = await fetch("https://smartists.tdep.workers.dev/getauthtoken")
  const token = await response.text()
  console.log(token)
  return token
}



const xBullAcquiresaNFT = async () => {
  const permissions = await window.xBullSDK.connect({
      canRequestPublicKey: true,
      canRequestSign: true
  });
  const publicKey_x = await window.xBullSDK.getPublicKey();
  console.log(publicKey_x)
  const unsigned_xdr = getXDR(publicKey_x)

}

function signXDR(resp, resp1) {
  const transaction = new Transaction(resp.xdr, Networks.PUBLIC)
  transaction.addSignature(resp.signer, resp.signature)
  transaction.addSignature(resp1.signer, resp1.signature)

  return transaction.toXDR()
}


const getXDR = async (buyerPK) => {
  const authHeader = await getAuthToken()
  const fees = await server.fetchBaseFee()
  const timebounds = await server.fetchTimebounds(100)
  const buy_offer = document.getElementById("buy_offer").value
  const saNFT_id = document.getElementById("saNFT_id").innerText

  const data = {
    userPK: buyerPK,
    fee: fees,
    time_bounds: timebounds,
    buy_offer: buy_offer,
    saNFT_id: saNFT_id,
  }

  console.log(JSON.stringify(data))

  const sdf_turret = {
    method: 'post',
    url: `https://stellar-turrets-alpha.sdf-ecosystem.workers.dev/tx-functions/${txHash}`,
    headers: { 
      'Authorization': `Bearer ${authHeader}`, 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  const script3_turret = {
    method: 'post',
    url: `https://stellar-turrets-alpha.script3.workers.dev/tx-functions/${txHash}`,
    headers: { 
      'Authorization': `Bearer ${authHeader}`, 
      'Content-Type': 'application/json'
    },
    data : data
  };

  let xdr;
  await axios.all([
    axios(sdf_turret),
    axios(script3_turret),
  ])
  .then(axios.spread((tss_out_data1, tss_out_data2) => {
    console.log(tss_out_data1, tss_out_data2);
    const xdr = signXDR(tss_out_data1.data, tss_out_data2.data)

  }))
  
  const signed_xdr = await window.xBullSDK.signXDR(xdr)
  const final_tx = new Transaction(signed_xdr, Networks.PUBLIC)

  console.log(final_tx.toEnvelope().toXDR('base64'))
  //server.submitTransaction(final_tx);

}


async function buildOwnersList(saNFT) {
  try {
    const trades = await server.trades().forAssetPair(saNFT, Asset.native()).limit(92).order("desc").call()
    let owners = []
    let records = trades.records
    let totSupply = []
    for (let i=0; i < records.length; i++) {
      totSupply.push(parseFloat(records[i].counter_amount))
    }
    const sumTotalSupply = Math.round((totSupply.reduce((a, b) => a + b, 0)) * 1000000) / 1000000
    for (let i=0; i < records.length; i++) {
        owners.push({
          "ownerPK":records[i].counter_account,
          "ownPerc": (parseFloat(records[i].counter_amount) * parseFloat(100)) / sumTotalSupply
        })     
    }
    owners = owners.sort((a, b) => (a.ownPerc < b.ownPerc) ? 1 : -1) // sorting the array of objects by the percentage of the owned saNFT network of that owner.
    if (owners.length > 92) { // if the number of owners is greater than 92, we will take the top 92 owners
      owners.splice(93, owners.length)
    } 
    return owners
  } catch (err) {
    return []
  }
}


const SaItem = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q')
  const [code, setCode] = useState([]);
  const [b64, setB64] = useState([]);
  const [description, setDescription] = useState([]);
  const [issuer, setIssuer] = useState([]);
  const [owners, setOwners] = useState([]);

  useEffect( async () => {
    const response = await fetch(`https://api.jsonbin.io/v3/b/${q}`)
    const data = await response.json();
    const owners_resp = await buildOwnersList(new Asset(data.record[0].code, data.record[0].issuer))

    setOwners(owners_resp)
    setCode(data.record[0].code)
    setDescription(data.record[0].description)
    setIssuer(data.record[0].issuer)
    setB64(data.record[0].base64)
  }, []);

  //const code = data.record[0].code
  //const b64 = data.record[0].b64

  return (
    <div className="item-container">
      <div className="item-header">
        <h1>{code}</h1>
        <p>{description}</p>
        <div className="img-div-item">
          <div className="img-div-head">
            <img src={`${b64}`}></img>
          </div>
        </div>
      </div>
      <div className="item-footer">
        <h4>Info</h4>
        <table>
    
          <tr>
            <td><p>Artist</p></td>
            <td><p>{issuer}</p></td>
          </tr>
          <tr>
            <td><p>Asset Code</p></td>
            <td><p id="saNFT_id">{code}</p></td>
          </tr>
          <tr>
            <td><p>Number of Owners</p></td>
            <td><p>{owners.length}</p></td>
          </tr>
          <tr>
            <td><p>Artist Fee</p></td>
            <td><p>5%</p></td>
          </tr>
          <tr>
            <td><p>Pool Fund</p></td>
            <td><p>15%  <br/>
            [the higher the pool fund %, the more probability you have to earn as it brings more volume to the asset]<br/><br/>
            GCUH5CLBY5TAW7MKIN3T7MO3RARF2JAQSAVGCI2YDTIVVO2QAHUQ46GC </p></td>
          </tr>
        </table>

        <div className="wallets-integration">
          <input type="text" placeholder="Enter Your Buy Offer in XLM" id="buy_offer"/>
          <div className="xbull"><button onClick={xBullAcquiresaNFT}>Buy with xBull</button></div>
          {/*<div className="albedo"><a>Get it with Albedo</a></div> */}
        </div>
       
      </div>
    </div>
  )
}

export default SaItem
