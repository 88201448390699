import React  from "react";
import { Link } from "react-router-dom";
import css from '../../main.css'
import saNFTimg from '../../static/saNFT.png'
import saHorusNFT1 from '../../static/HorusNFT14.gif'
import saHorusNFT2 from '../../static/saHorusNFT4.gif'
import saHorusNFT3 from '../../static/HorusNFT3.gif'
import saHorusNFT4 from '../../static/HorusNFT4.gif'
import collName from '../../static/collName.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import saHorusCollection from '../../static/first_collection.png'
import stellarModLogo from '../../static/stellar.png'
import nftsImg from '../../static/nft.png'
import passively from '../../static/passively.png'
import workflow from '../../static/workflow.svg'
import support from '../../static/support.png'
import unique from '../../static/unique.png'
import { FaMoneyBillWaveAlt, FaLock, FaBolt, FaTwitter, FaDiscord, FaMedium } from 'react-icons/fa';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect
import { Widget } from '@typeform/embed-react'
import logo from '../../static/SmartArtists.png'


// create a component
//
function MarketPlaceHome() {
    return (
        <div > 
            <nav>
              
                
              <div className="nav-body">
                <div class="logo">
                  <a href="/"><img id="logo" src={logo}></img></a>
                </div>
                  <ul id="js-menu">
                      <li><a href="/">Home</a></li>
                      <li><a target="_blank" href="https://docs.smartartists.art/">Learn</a></li>
                      <li><a href="/#horus-collection">Marketplace</a></li>
                      <li><a target="_blank" href="https://twitter.com/smartartistsnft">Updates</a></li>
                      
                  </ul>
            </div>
          </nav>
                <div id="main-home"></div>
                <div className="project-intro">

                    <div className="project-intro-header">
                        <h1><a  href="https://stellar.org/" target="_blank" className="info-link">Stellar</a> DeFi powering a marketplace for non-standard NFTs </h1>
                        <h3 className="info-text header-link">Built with <a className="info-link" target="_blank" href="https://tss.stellar.org/">Stellar Turrets</a></h3>

                        <div className="header-showcase">
                            <div className="header-showcase-item">
                                <a href="#get-started-dest" className="violet heading-link">Get Started</a>
                                {/*<p>Use our educational resources to completely understand our project and start earning with it. <br/><br/>You will find both vides and document resources, sit back and learn.</p>
                                <FontAwesomeIcon className="head-icon" icon="book" />*/}
                            </div>
                            
                        </div>
                    </div>
                    <Zoom>
                    <div id='get-started-dest'></div>

                <div className="project-intro-body-1">
                    <h1 class="head-h1">A new kind of NFTs</h1>
                    <div className="project-intro-body">
                        <div className="project-intro-body-expl">
                            <p>Leveraging Stellar Smart contracts, when you buy a smartartistsNFT you will be doing much more than just getting an NFT into your wallet. </p>
                        </div>
                        <div className="project-intro-features">
                            <div className="intro-c ard">
                                <div className="intro-card-header">
                                    <img src={passively}/>
                                    <h2>Earn Passively</h2>
                                </div>
                            </div>
                            <div className="intro-card">
                                <div className="intro-card-header">
                                    <img src={support}/>
                                    <h2>Support Artist</h2>
                                </div>
                            </div>
                            <div className="intro-card">
                                <div className="intro-card-header">
                                    <img src={unique}/>
                                    <h2>Unique NFT</h2>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </Zoom>
                <Zoom>

                    <div id='get-started'>
                        <div className="get-started-item">
                            <div className="rotate-on-hover"><a target="_blank" href="https://docs.smartartists.art/" className="head-button">Learn</a></div>
                            <p>Learn about our platform and project. <br/><br/>You will find video educational resources as well as a more in-depth documentation</p>
                        </div>
                        <div className="get-started-item">
                            <div className="rotate-on-hover"><a href="#horus-collection" className="head-button">Collection</a></div>
                            <p>Explore the first collection of saNFTs. <br/><br/>For now there's no option of artists to upload their own saNFTs. However, if you are interested, reach us out on <a href="https://twitter.com/smartartistsnft">Twitter</a></p>
                        </div>

                    </div>
                </Zoom>
                
                <Zoom>
                <div className="project-intro-body-1">
                    <h1 class="head-h1">Built on Stellar</h1>
                    <div className="project-intro-body">
                        <div className="project-intro-body-expl">
                            <p>Everything running on SmartArtists is entirely built upon the Stellar network. This comes with many advantages.</p>
                        </div>
                        
                        <div className="features-list">
                            <div className="features-list-item">
                                <div className="features-list-item-header">
                                    <FaMoneyBillWaveAlt className="fa-icon"/>
                                    <h2>{/*<FaMoneyBillWaveAlt/>*/} Low Fees</h2>
                                </div>
                                <div className="features-list-item-body">
                                    <p>We take care of fees so you don't have to. That's why Stellar's incredibly low fees are important for us.</p>
                                </div>
                            </div>
                            <div className="features-list-item">
                                <div className="features-list-item-header">
                                    <FaLock className="fa-icon"/>
                                    <h2>Security</h2>
                                </div>
                                <div className="features-list-item-body">
                                    <p>Everything you'll be running will be on the Stellar Network.</p>
                                </div>
                            </div>
                            <div className="features-list-item">
                                <div className="features-list-item-header">
                                    <FaBolt className="fa-icon"/>
                                    <h2>Performance</h2>
                                </div>
                                <div className="features-list-item-body">
                                    <p>The Stellar Network allows you to execute faster transactions than most other blockchains.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </Zoom>
            </div>
            {/*
            <div className="project-workflow">
                <img src={workflow}></img>
            </div>
            */}
            
            <Zoom>
        
        <div style={{margin: "auto", height: "500px", width: "800px", marginTop: "10vh"}}>
        <iframe loading="lazy" style={{position: 'relative', height: "500px", width: "800px", border: 'none', borderRadius:"20px"}} src="https://www.canva.com/design/DAEzMvX3R_8/watch?embed" allowFullScreen="allowfullscreen" allow="fullscreen"></iframe>
        </div>
            <div id="horus-collection">
                <Widget id="PSCjHpj1" style={{ width: '100%', height: "1000px" }} className="my-form" />
                
                
            </div>
            {/*
            <div className="buy-sanft">
                <div className="buy-sanft-header">
                    <h1>Before you Buy an <span class="nfth1">NFT</span> <span class="sanfth1">saNFT</span></h1>
                </div>
                <div className="buy-sanft-body">
                    <p>This is still an experimental project, be sure you understand what you are buying! Below there are all the resources you need to learn, don't worry, we'll wait for you 😍</p>
                    <div className="buy-sanft-body-resources">
                        <ul>
                            <li><a href="#">NFTs vs saNFTs</a></li>
                            <li><a href="#">How you Earn</a></li>
                            <li><a href="#">What to excpect after buying</a></li>
                            <li><a href="#">Investing Guide</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="foot-container">
                    <div className="footer-item">
                        <ul>
                            <li><a href="https://docs.smartartists.art/">Documentation</a></li>
                            <li><a href="https://docs.smartartists.art/">Buy saNFTs</a></li>
                        </ul>
                    </div>
                    <div className="footer-item">
                        <a href="https://twitter.com/smartartistsnft"><FaTwitter className="footer-icon"></FaTwitter> </a>
                        <a href="https://twitter.com/smartartistsnft"><FaMedium className="footer-icon"></FaMedium> </a>
                        <a href="https://twitter.com/smartartistsnft"><FaDiscord className="footer-icon"></FaDiscord> </a>
                    </div>
                </div>
                    <h3><a href="/">© SmartArtists 2021</a></h3>
            </div>*/}
                </Zoom>
                <div className="footer">
                    <p><a href="/">© SmartArtists 2021</a></p>
                </div>
        </div>
    )
}

export default MarketPlaceHome;
