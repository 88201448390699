import React  from "react";
import { FaMoneyBillWaveAlt, FaLock, FaBolt, FaTwitter, FaDiscord, FaMedium } from 'react-icons/fa';
import { BiWorld } from "react-icons/bi";
import { BsMedium } from "react-icons/bs";
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect
import { Widget } from '@typeform/embed-react'
import logo from '../../static/SmartArtists.png'
import logoCube from '../../static/logo_fin_cube.png'
import stellarCube from '../../static/stellar_cube.png'
import passively from '../../static/passively.png'
import support from '../../static/support.png'
import unique from '../../static/unique.png'


// create a component
//
function Home() {
    return (
        <div className="home">
            <nav>                
              <div className="nav-body">
                <div class="logo">
                  <a href="/"><img id="logo" src={logo}></img></a>
                </div>
                  <ul id="js-menu">
                      <li className="text-li"><a href="/">Home</a></li>
                      <li className="text-li"><a target="_blank" href="https://docs.smartartists.art/">Learn</a></li>
                      <li className="text-li"><a href="/#horus-collection">Marketplace</a></li>
                      <li className="icon-li"><a target="_blank" href="https://discord.gg/8wqG97gDrZ"><FaDiscord/></a></li>
                      <li className="icon-li"><a target="_blank" href="https://twitter.com/smartartistsnft"><FaTwitter/></a></li>
                      <li className="icon-li"><a target="_blank" href="https://blog.smartartists.art"><BsMedium/></a></li>
                      
                  </ul>
            </div>
          </nav>
          <div id="stage">
          <div class="spinner">
        <div class="face1">  <img id="logoCube" src={logoCube}></img></div>
        <div class="face2">  <img id="logoCube" src={stellarCube}></img></div>
        <div class="face3">  <img id="logoCube" src={logoCube}></img></div>
        <div class="face4">  <img id="logoCube" src={stellarCube}></img></div>
        <div class="face5">  <img id="logoCube" src={logoCube}></img></div>
        <div class="face6">  <img id="logoCube" src={stellarCube}></img></div>
    </div>
          
          <div class="wrapper">
  
</div>
            </div>
            <div className="home-welcomepage">
                <div className="home-welcomepage-content">
                    <div className="home-welcomepage-content-title">
                        <h1>A new kind of NFTs.</h1>
                        <h1>On Stellar DeFi.</h1>
                    </div>
                    <div className="home-welcomepage-content-subtitle">
                      <h3>Share the ownership of an NFT.</h3>
                      <h3>Buy part of its network.</h3>
                      <h3>Earn passively from it.</h3>
                      <div id="get-started-button-container">
                        <a href="#part-1">Get Started Now</a>
                      </div>
                    </div>
                </div>
            </div>
            
            <div className="home-welcomepage-content-description">

              <div className="description-part title-primary">
                <div id="part-1"></div>
                <Zoom top>
                <h1 >Smart Artists introduces</h1>
                <h1 ><span className="gradient-text">saNFTs</span>.</h1>
                </Zoom>
                <div className="description-part-body">
                  <Zoom left>
                  <div id="sanft-features">
                    
                    <h3 style={{marginRight:"5vw"}}>Leveraging Stellar Smart contracts, when you buy a smartartistsNFT you will be doing much more than just getting an NFT into your wallet. <br/><br/> saNFTs bring stellar assets and stellar-based smart contracts together. All while being decentralized thanks to Stellar Turrets.</h3>
                  </div>
                  </Zoom>
                  <div id="sanft-video">
                    <div style={{margin: "auto", maxHeight: "300px", maxWidth: "600px", marginTop: "0"}}>
                      <iframe loading="lazy" style={{position: 'relative', height: "350px", width: "100%", border: 'none', borderRadius:"20px"}} src="https://www.canva.com/design/DAEzMvX3R_8/watch?embed" allowFullScreen="allowfullscreen" allow="fullscreen"></iframe>
                    </div>
                  </div>
                </div>
              </div>

              
              <div className="description-part center title-secondary">
              <Zoom top>
                  <h1 style={{textAlign:'center'}}>Built on Stellar</h1>

                  <div >
                    <p className="subtitle">Everything running on SmartArtists is entirely built upon the Stellar network. This comes with many advantages.</p>
                  </div>
                        
                </Zoom>

                
                  <div className="stellar-features-list">
                  <Zoom left>
                    <div className="stellar-features-list-item">
                      <div className="stellar-features-list-item-header">
                        <FaMoneyBillWaveAlt className="fa-icon"/>
                          <h2>{/*<FaMoneyBillWaveAlt/>*/} Low Fees</h2>
                      </div>
                      <div className="stellar-features-list-item-body">
                            {/*<p>We take care of fees so you don't have to. That's why Stellar's incredibly low fees are important for us.</p>*/}
                      </div>
                    </div>
                      <div className="stellar-features-list-item">
                        <div className="stellar-features-list-item-header">
                          <FaLock className="fa-icon"/>
                            <h2>Security</h2>
                        </div>
                        <div className="stellar-features-list-item-body">
                            {/*<p>Every transaction will be on top of the Stellar Network, ensuring a high level of security.</p>*/}
                        </div>
                      </div>
                      <div className="stellar-features-list-item">
                        <div className="stellar-features-list-item-header">
                          <FaBolt className="fa-icon"/>
                          <h2>Performance</h2>
                        </div>
                        <div className="stellar-features-list-item-body">
                                    {/*<p>The Stellar Network allows you to execute faster transactions than most other blockchains.</p>*/}
                        </div>
                      </div>
                      <div className="stellar-features-list-item">
                        <div className="stellar-features-list-item-header">
                          <BiWorld className="fa-icon"/>
                          <h2>Open</h2>
                        </div>
                        <div className="stellar-features-list-item-body">
                                    {/*<p>The Stellar Network allows you to execute faster transactions than most other blockchains.</p>*/}
                        </div>
                      </div>
                      </Zoom>

                    </div>

                
                        <div id="next-steps">
                        <Zoom top>
                          <h1 className="title-secondary">Next Steps</h1>  
                        </Zoom>
                          <div id='go-further'>
                          <Zoom left>
                            <div className="go-further-card">
                            <a href="https://docs.smartartists.art"><div className="go-further-card-button">
                                Learn
                              </div></a>
                              <p>A must-read before buying saNFTs.</p>
                              <p>Access our educational videos.</p>
                              <p>Full documentation.</p>
                            </div>
                            <div className="go-further-card">
                            <a href="#horus-collection"><div className="go-further-card-button">
                                Marketplace
                              </div></a>
                              <p>Discover the existing collections of saNFTs.</p>
                            </div>
                            </Zoom>
                          </div>
                        </div>

                        <div id="community">
                        <Zoom top>
                          <h1 className="title-secondary">Community</h1>
                        
                          <p className="subtitle">With our governance protocol, our platform will be controlled and updated by the community. The more we interact the better the platform will build, below there are the social channels we use to interact with the Smart Artists community.</p>
                    </Zoom>
                          <div id='community-body'>
                          <Zoom left>
                          <a target="_blank" href="https://discord.gg/8wqG97gDrZ"><div className="community-card">
                              <div className="community-card-button discord"><FaDiscord/></div>
                              <p>Ask questions and connect with the community: we recommend joining. See you there.</p>
                            </div></a>
                            <a target="_blank" href="https://twitter.com/smartartistsnft"><div className="community-card">
                              <div className="community-card-button twitter"><FaTwitter/></div>
                              <p>The media we will use to interact with other projects and make announcements.</p>
                            </div></a>
                            <a target="_blank" href="https://blog.smartartists.art"><div className="community-card">
                              <div className="community-card-button medium"><BsMedium/></div>
                              <p>Smart Artists blog, detailed info about upcoming updates and future features.</p>
                            </div></a>
                            </Zoom>
                          </div>
                        </div>
              </div>
            </div>
            <div id="horus-collection">
              <Zoom>
                  <Widget id="PSCjHpj1" style={{ width: '100%', height: "700px" }} className="my-form" />
              </Zoom>
              </div>
              
        </div>
    )
}

export default Home;
