import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  Demo,
  MarketPlaceHome,
  GetSmarTdep,
  Manifest,
  Item,
  GetSaNFT,
  Home,
} from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/fontawesome-free-solid"
import logoHead from './static/logo3.png';
import css from './main.css'
import saNFTimg from './static/saNFT.png'
import logo from './static/SmartArtists.png'


ReactDOM.render(
  
  <Router>
    <div className="marketplace-home">
            {/*<div className="mrk-nav">
                <p><a href="https://smartartistsnfts.github.io/"><span style={{"color":"#A600FF"}}>SmartArtists</span></a><span className="txt-logo"> is an awesome Marketplace for non-standard NFTs. Read the <a href="https://www.canva.com/design/DAEwY4NM08I/7qM9l7DxXhn6DqJugaj1yg/view?utm_content=DAEwY4NM08I&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink">Litepaper</a></span></p>
            </div>
            <div className="mrk-header">
                <div className="mrk-header-left">
                    <a href="/"><img src={logoHead}/></a>
                </div>

                <div className="mrk-header-explorer">
                    <div className="expl-head">
                        <span>Learn</span>
                        </div>
                    <ul>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/"><FontAwesomeIcon icon="flask" /> How It Works</a></li>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/are-you-an-artist/"><FontAwesomeIcon icon="plus-square" /> Create Your saNFT</a></li>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/tradingnomics/"><FontAwesomeIcon icon="hand-holding-usd" /> How to Earn</a></li>
                        <li className="expl-it"><a target="_blank" href="https://discord.gg/3T2VrRYN"><FontAwesomeIcon icon="question-circle" /> Got any Questions?</a></li>
                    </ul>
                </div>

            </div>
            */}
            {/* set up simple navbar */}
            
      </div>
    <Routes>
      {/*<Route path="/" element={<Redir/>}/>*/}
      <Route path="/" element={<Home />} />
      {/*<Route path="/home" element={<MarketPlaceHome/>} />
      <Route path="/saNFT" element={<Item/>}/>
      <Route path="/getsaNFT" element={<GetSaNFT/>}/>
      <Route path="/demo" element={<Demo />} />
      <Route path="/manifest" element={<Manifest />} />
      <Route path="/getSmarTdep" element={<GetSmarTdep/>}/> */}
    </Routes>

  </Router>,

  document.getElementById("root")
);

